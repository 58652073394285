
.carousel {
  /* margin-bottom: 4rem; */
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 50rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 50rem;
}
/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 300;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}

.title-carousel{
    font-size: 80px;
    font-weight : bold;
}

.title-percent{
    position:absolute;
    font-size: 30px !important;
    top: 85px;
    left: 200px;
}

.title-off{
    position:absolute;
    font-size: 30px !important;
    top: 120px;
    left: 200px;
}

.title-shop{
    position:absolute;
    font-size: 13px !important;
    top: 155px;
    left: 230px;
}

.shop-now-button{
    position:absolute;
    width: 120px;
    font-size: 12px !important;
    left: 10px;
    top: 200px;
    background-color: #9B9B9B9B;
}

.shop-from-button{
    position:absolute;
    width: 120px;
    font-size: 12px !important;
    left: 160px;
    top: 200px;
    background-color: #424141 !important
}



.btn-control-prev-icon{
        background-color: #000000 !important;
}

.btn-control-next-icon{
        background-color: #000000 !important;
}

.carousel-indicators li {
      width: 20px;
      height: 20px;
      border-radius: 100%;
}

.carousel-indicators .active{
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }

  .title-carousel{
      font-size: 130px;
      font-weight : bold;
  }
  .title-percent{
      position:absolute;
      font-size: 50px !important;
      top: 90px;
      left: 320px;
  }

  .title-off{
      position:absolute;
      font-size: 55px !important;
      top: 140px;
      left: 320px;
  }

  .title-shop{
      position:absolute;
      font-size: 18px !important;
      top: 205px;
      left: 350px;
  }

  .shop-now-button{
      position:absolute;
      width: 150px;
      font-size: 17px !important;
      left: 100px;
      top: 250px;
      background-color: #9B9B9B9B
  }

  .shop-from-button{
      position:absolute;
      width: 150px;
      font-size: 17px !important;
      left: 270px;
      top: 250px;
      background-color: #424141 !important
  }
}
