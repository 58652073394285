.mycustom-jumbotron {
  /* margin: 19 auto; */
  background-color: rgb(245, 245, 245);
  /* max-height: 20% !important ; */
  padding: .1em !important;
  margin: 0 auto;
}

.serch-input{
    border-color: #e9e9e9;
    font-size: 14.5px;

}

.dropdown_category{
        font-size: 11px;
        border-color: rgba(237, 235, 235, 1) !important;
        background-color: rgba(255, 255, 255, 0.91) !important;
    }

    .dropdown_wishlist{
        border-color: rgba(255, 255, 255, 0) !important;
        background-color: rgba(255, 255, 255, 0) !important;
    }

    .btn-light{
        border-color: rgb(217, 217, 217);
    }

    .form-control::-webkit-input-placeholder {
      color: #c9c9c9;
    }
